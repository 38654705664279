<template>
   <div class="p-10">
      <section class="grid grid-cols-1 md:grid-cols-3 gap-5 my-5">
         <span class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     {{ dashboardSummery.total_users || 0 }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total Users</div>
               </div>
            </div>
         </span>
         <span class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                  </svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     {{ dashboardSummery.total_verified_users || 0 }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total Verified Users</div>
               </div>
            </div>
         </span>
         <span class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7a4 4 0 11-8 0 4 4 0 018 0zM9 14a6 6 0 00-6 6v1h12v-1a6 6 0 00-6-6zM21 12h-6" />
                  </svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     {{ dashboardSummery.total_unverified_users || 0 }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total Unverified Users</div>
               </div>
            </div>
         </span>
         <span class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <span class="text-5xl text-gray-300">&#8358;</span>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#8358;{{ dashboardSummery.total_ngn_balance || 0 | formatPrice }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total NGN Balance</div>
               </div>
            </div>
         </span>
         <span class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <span class="text-5xl text-gray-300">&#36;</span>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#36;{{ dashboardSummery.total_usd_balance || 0 | formatPrice }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total USD Balance</div>
               </div>
            </div>
         </span>
         <span class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <span class="text-5xl text-gray-300">&#165;</span>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#165;{{ dashboardSummery.total_cny_balance || 0 | formatPrice }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total CNY Balance</div>
               </div>
            </div>
         </span>
         <span class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="w-10 h-10 text-gray-300"><g fill="none" fill-rule="evenodd"><circle cx="16" cy="16" r="16" fill="currentColor"/><path fill="#FFF" fill-rule="nonzero" d="M23.189 14.02c.314-2.096-1.283-3.223-3.465-3.975l.708-2.84-1.728-.43-.69 2.765c-.454-.114-.92-.22-1.385-.326l.695-2.783L15.596 6l-.708 2.839c-.376-.086-.746-.17-1.104-.26l.002-.009-2.384-.595-.46 1.846s1.283.294 1.256.312c.7.175.826.638.805 1.006l-.806 3.235c.048.012.11.03.18.057l-.183-.045-1.13 4.532c-.086.212-.303.531-.793.41.018.025-1.256-.313-1.256-.313l-.858 1.978 2.25.561c.418.105.828.215 1.231.318l-.715 2.872 1.727.43.708-2.84c.472.127.93.245 1.378.357l-.706 2.828 1.728.43.715-2.866c2.948.558 5.164.333 6.097-2.333.752-2.146-.037-3.385-1.588-4.192 1.13-.26 1.98-1.003 2.207-2.538zm-3.95 5.538c-.533 2.147-4.148.986-5.32.695l.95-3.805c1.172.293 4.929.872 4.37 3.11zm.535-5.569c-.487 1.953-3.495.96-4.47.717l.86-3.45c.975.243 4.118.696 3.61 2.733z"/></g>
                  </svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     {{ dashboardSummery.total_btc_balance || 0.00000000 }} BTC
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total BTC Balance</div>
               </div>
            </div>
         </span>
         <span class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="w-10 h-10 text-gray-300"><g fill="none" fill-rule="evenodd"><circle cx="16" cy="16" r="16" fill="currentColor"/><path fill="#FFF" d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658 0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061 1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658 0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118 0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116 0-1.043-3.301-1.914-7.694-2.117"/></g>
                  </svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     {{ dashboardSummery.total_usdt_balance || 0 | formatPrice }} USDT
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total USDT Balance</div>
               </div>
            </div>
         </span>
         <span class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="w-10 h-10 text-gray-300"><g fill="none"><circle cx="16" cy="16" r="16" fill="currentColor"/><path fill="#FFF" d="M12.116 14.404L16 10.52l3.886 3.886 2.26-2.26L16 6l-6.144 6.144 2.26 2.26zM6 16l2.26-2.26L10.52 16l-2.26 2.26L6 16zm6.116 1.596L16 21.48l3.886-3.886 2.26 2.259L16 26l-6.144-6.144-.003-.003 2.263-2.257zM21.48 16l2.26-2.26L26 16l-2.26 2.26L21.48 16zm-3.188-.002h.002V16L16 18.294l-2.291-2.29-.004-.004.004-.003.401-.402.195-.195L16 13.706l2.293 2.293z"/></g>
                  </svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     {{ dashboardSummery.total_busd_balance || 0 | formatPrice }} BUSD
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total BUSD Balance</div>
               </div>
            </div>
         </span>
         <div class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <span class="text-5xl text-gray-300">&#8358;</span>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#8358;{{ dashboardSummery.total_ngn_deposits || 0 | formatPrice }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total NGN Deposit</div>
               </div>
            </div>
         </div>
         <div class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <span class="text-5xl text-gray-300">&#36;</span>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#36;{{ dashboardSummery.total_usd_deposits || 0 | formatPrice }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total USD Deposit</div>
               </div>
            </div>
         </div>
         <div class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <span class="text-5xl text-gray-300">&#8358;</span>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#8358;{{ dashboardSummery.total_ngn_withdrawals || 0 | formatPrice }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total NGN Withdrawal</div>
               </div>
            </div>
         </div>
         <div class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <span class="text-5xl text-gray-300">&#36;</span>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#36;{{ dashboardSummery.total_usd_withdrawals || 0 | formatPrice }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total USD Withdrawal</div>
               </div>
            </div>
         </div>
         <div class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-gray-300" viewBox="0 0 20 20" fill="currentColor">
                     <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                     <path fill-rule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clip-rule="evenodd" />
                  </svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#8358;{{ dashboardSummery.total_escrow || 0 | formatPrice }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total Escrow Amount</div>
               </div>
            </div>
         </div>
         <div class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#8358;{{ dashboardSummery.total_completed_escrow || 0 | formatPrice }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total Completed Escrow Amount</div>
               </div>
            </div>
         </div>
         <div class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-12 h-12 text-gray-300"><path fill="currentColor" d="M6.8042,15A.99956.99956,0,0,0,5.438,14.63379l-1.73242,1a1.00016,1.00016,0,0,0,1,1.73242l1.73242-1A1.00073,1.00073,0,0,0,6.8042,15ZM3.70557,8.36621l1.73242,1a1.00016,1.00016,0,1,0,1-1.73242l-1.73242-1a1.00016,1.00016,0,0,0-1,1.73242ZM6,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H5A1,1,0,0,0,6,12ZM17.1958,9a1.0006,1.0006,0,0,0,1.36621.36621l1.73242-1a1.00016,1.00016,0,1,0-1-1.73242l-1.73242,1A1.00073,1.00073,0,0,0,17.1958,9ZM15,6.8042A1.0006,1.0006,0,0,0,16.36621,6.438l1-1.73242a1.00016,1.00016,0,1,0-1.73242-1l-1,1.73242A1.00073,1.00073,0,0,0,15,6.8042Zm5.29443,8.82959-1.73242-1a1.00016,1.00016,0,1,0-1,1.73242l1.73242,1a1.00016,1.00016,0,0,0,1-1.73242ZM16.36621,17.562a1.00016,1.00016,0,1,0-1.73242,1l1,1.73242a1.00016,1.00016,0,1,0,1.73242-1ZM21,11H19a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Zm-9,7a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V19A1,1,0,0,0,12,18Zm-3-.8042a.99954.99954,0,0,0-1.36621.36621l-1,1.73242a1.00016,1.00016,0,0,0,1.73242,1l1-1.73242A1.00073,1.00073,0,0,0,9,17.1958ZM12,2a1,1,0,0,0-1,1V5a1,1,0,0,0,2,0V3A1,1,0,0,0,12,2Z"/></svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#8358;{{ dashboardSummery.total_pending_escrow || 0 | formatPrice }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total Pending Escrow Amount</div>
               </div>
            </div>
         </div>
         <div class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#8358;{{ dashboardSummery.total_rejected_escrow || 0 | formatPrice }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total Rejected Escrow Amount</div>
               </div>
            </div>
         </div>
         <div class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-12 h-12 text-gray-300"><path fill="currentColor" d="M13,16H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2ZM9,10h2a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Zm12,2H18V3a1,1,0,0,0-.5-.87,1,1,0,0,0-1,0l-3,1.72-3-1.72a1,1,0,0,0-1,0l-3,1.72-3-1.72a1,1,0,0,0-1,0A1,1,0,0,0,2,3V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A1,1,0,0,0,21,12ZM5,20a1,1,0,0,1-1-1V4.73L6,5.87a1.08,1.08,0,0,0,1,0l3-1.72,3,1.72a1.08,1.08,0,0,0,1,0l2-1.14V19a3,3,0,0,0,.18,1Zm15-1a1,1,0,0,1-2,0V14h2Zm-7-7H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z"/></svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#8358;{{ dashboardSummery.total_disputed_escrow || 0 | formatPrice }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total Disputed Escrow Amount</div>
               </div>
            </div>
         </div>
         <div class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                  </svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#8358;{{ dashboardSummery.total_held_escrow || 0 | formatPrice }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total Held Escrow Amount</div>
               </div>
            </div>
         </div>
         <div class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-12 h-12 text-gray-300"><path fill="currentColor" d="M20.64026,15.77179A3.46849,3.46849,0,0,0,21.5,13.5a3.5,3.5,0,0,0-7,0,3.46849,3.46849,0,0,0,.85974,2.27179A4.98821,4.98821,0,0,0,13,20a1,1,0,0,0,2,0,3,3,0,0,1,6,0,1,1,0,0,0,2,0A4.98821,4.98821,0,0,0,20.64026,15.77179ZM18,15a1.5,1.5,0,1,1,1.5-1.5A1.50164,1.50164,0,0,1,18,15ZM6.79327,7.70734,8.793,9.707A.99989.99989,0,0,0,10.207,8.293L9.91406,8h4.17188l-.293.293A.99989.99989,0,1,0,15.207,9.707l1.9997-1.99969a1.00354,1.00354,0,0,0,0-1.41468L15.207,4.293A.99989.99989,0,0,0,13.793,5.707l.293.293H9.91406l.293-.293A.99989.99989,0,0,0,8.793,4.293L6.79327,6.29266a1.00354,1.00354,0,0,0,0,1.41468Zm1.847,8.06445A3.46849,3.46849,0,0,0,9.5,13.5a3.5,3.5,0,0,0-7,0,3.46849,3.46849,0,0,0,.85974,2.27179A4.98821,4.98821,0,0,0,1,20a1,1,0,0,0,2,0,3,3,0,0,1,6,0,1,1,0,0,0,2,0A4.98821,4.98821,0,0,0,8.64026,15.77179ZM6,15a1.5,1.5,0,1,1,1.5-1.5A1.50164,1.50164,0,0,1,6,15Z"/></svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#8358;{{ dashboardSummery.total_resolved_escrow || 0 | formatPrice }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total Resolved Escrow Amount</div>
               </div>
            </div>
         </div>
         <div class="group p-5 bg-white shadow rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z" />
                  </svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     {{ dashboardSummery.support_tickets || 0 }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Customer Support Tickets</div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
   name: 'Dashboard',

   components: {
      
   },

   data() {
      return {
         isLoading: false,
         dashboardSummery: []
      }
   },
   
   methods: {
      ...mapActions('admin', ['get_dashboard_summery']),

      async getDashboardSummery() {
         this.isLoading = true

         try {
            let res = await this.get_dashboard_summery()
            this.dashboardSummery = res.data
            this.isLoading = false
         } catch(error) {
            this.isLoading = false
         }
      }
   },
   
   computed: {
      ...mapGetters("auth", ["admin"]),
   },

   mounted() {
      this.getDashboardSummery()
   }
}
</script>